module.exports = [{
      plugin: require('../../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"xxx","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-source-wordpress-experimental/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://dev-designtestlocal.pantheonsite.io/graphql","schema":{"perPage":600,"timeout":1000000,"requestConcurrency":50,"previewRequestConcurrency":50,"queryDepth":15,"circularQueryLimit":5,"typePrefix":"Wp"},"verbose":true,"develop":{"nodeUpdateInterval":3000,"hardCacheMediaFiles":true,"hardCacheData":false},"production":{"hardCacheMediaFiles":false},"debug":{"graphql":{"showQueryOnError":false,"showQueryVarsOnError":true,"copyQueryOnError":true,"panicOnError":true,"onlyReportCriticalErrors":true,"copyNodeSourcingQueryAndExit":false,"writeQueriesToDisk":false},"timeBuildSteps":false,"disableCompatibilityCheck":false,"throwRefetchErrors":false},"excludeFieldNames":["blocksJSON","saveContent"],"type":{"Post":{"limit":5000}}},
    },{
      plugin: require('../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Goonut E-store","short_name":"Electra","start_url":"/","background_color":"#eaeaea","theme_color":"#1e1e1e","display":"standalone","icon":"/opt/render/project/src/packages/gatsby-woocommerce-theme/src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"17582e7993a5a8b0af51f94a93682367"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../packages/gatsby-woocommerce-theme/gatsby-browser.js'),
      options: {"plugins":[],"wordPressUrl":"https://dev-designtestlocal.pantheonsite.io","gatsbySiteUrl":"https://goonut.onrender.com","googleTagManagerId":"xxx","fbAppId":"xxx"},
    }]
